import { Logo, Text } from '@corellium/metal';
import Link from 'next/link';
import type { FC } from 'react';

export const CloudAdminLogo: FC = () => (
  <Link
    href="/"
    className="flex items-center gap-2 text-gray-900 outline-none transition focus-visible:shadow-focus"
  >
    <Logo type="symbol" theme="dark" />
    <Text size="lg" weight="medium" className="select-none leading-none">
      Cloud Admin
    </Text>
  </Link>
);
